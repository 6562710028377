import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap, Power2, Power3, Back } from "gsap";
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { GetBy } from '../_app/cuchillo/core/Element';
import BezierEasing from 'bezier-easing'
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { SplitText } from 'gsap/SplitText';
import { Slider, Slider_Item } from '../_app/cuchillo/components/Slider';
import { KoraButtons } from '../components/KoraButtons';
import { Ease } from '../_app/cuchillo/utils/Ease';
import BG from '../_app/cuchillo/layout/Background';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

gsap.registerPlugin(DrawSVGPlugin);

class ScrollItem__SliderLocations extends VScroll_Item {
    _slider;
    _visorDOM;
    _controlsDOM;
    _visorHolderDOM;
    _is

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._visorDOM = GetBy.class("widget-locations-slider__visor", this.item)[0];
        this._visorHolderDOM = GetBy.class("holder", this._visorDOM)[0];
        this._controlsDOM = GetBy.class("__controls", this.item)[0];
        this._slider = new Slider(this.item, LocationSlider__Item);

        KoraButtons.get("SliderLocationProgress").total = this._slider._total;

        this.onShow = () => this.showEffect();
        this.onHide = () => { }
        this.onMove = () => {
            this.loop();
        };

        this._slider._items[0].directShowMedia();
    }

    showEffect() {
        this._slider.enabled = true;

        if (this.firstShow) {
            this._slider.goto(0);
        } else {
            this._slider._items[this._slider._actual].showColor();
        }
    }

    loop() {
        if (isSmartphone) return;

        const realY = this.realY;

        if (this.progress <= .5) {
            this._visorDOM.style.transformOrigin = "50% 50%";
            let scale = Maths.lerp(2, 0, this.progress);
            this._visorDOM.style.transform =
                CSS.scale3D(scale, scale, scale) + " " + CSS.translate3D(0, realY * .3, 0);

            scale = Maths.lerp(1.5, .5, this.progress);
            this._visorHolderDOM.style.transform = CSS.scale3D(scale, scale, scale) + " " + CSS.translate3D(0, realY * -.2, 0);

            this._visorDOM.style.opacity = 1


        } else {
            this._visorDOM.style.transformOrigin = "0% 100%";

            let scale = 1;//Maths.lerp(.8,1.2,this.progress);
            this._visorDOM.style.transform =
                CSS.scale3D(scale, scale, scale) + " " + CSS.translate3D(0, -realY, 0);

            scale = Maths.lerp(.7, 1.3, this.progress);

            this._visorHolderDOM.style.transform = CSS.scale3D(scale, scale, scale);
            this._visorHolderDOM.style.opacity = Maths.lerp(6, -3, this.progress);


        }
    }

    dispose() {
        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class LocationSlider__Item extends Slider_Item {
    static _z = 0;

    static getNewZ() {
        LocationSlider__Item._z++;
        return LocationSlider__Item._z;
    }

    data = {};
    timeline;
    mediaHolder;
    media;
    hasVideo = false;
    hasToAvoidFirstShow = false;

    constructor(__item, __index, __slider) {
        super(__item, __index, __slider);

        this.data = {
            link: this.item.getAttribute("data-link"),
            text: this.item.getAttribute("data-link-text")
        }

        this.setup();
        this.setupMedia();
    }

    setupMedia() {
        this.mediaHolder = GetBy.selector("figure", this.item.parentNode.parentNode)[this.index];
        if (GetBy.selector("video", this.mediaHolder)[0]) {
            this.hasVideo = true;
        }
        this.media = GetBy.selector("video", this.mediaHolder)[0] || GetBy.selector("img", this.mediaHolder)[0];
    }

    setup() {
        let i = 0;
        let delay = 0;
        this.timeline = gsap.timeline({ paused: true });

        //TITLE
        [...GetBy.class("__line", this.item)].map(item => {
            gsap.set(item, { alpha: 0, y: "102%" });

            this.timeline.to(item, {
                y: 0,
                duration: .8 + i * .15,
                ease: Ease.EASE_CUCHILLO_IN_OUT,
                force3D: true,
            }, delay);

            this.timeline.to(item, {
                alpha: 1,
                duration: .1,
                ease: Power2.easeIn,
                force3D: true,
            }, delay);
        });

        //ICONS
        delay = .5;
        [...GetBy.class("__icon", this.item)].map(item => {
            gsap.set(item, { alpha: 0, y: "200%", scale: 1 });

            this.timeline.to(item, {
                y: 0,
                duration: .8,
                ease: Back.easeOut,
                force3D: true,
            }, delay);

            this.timeline.to(item, {
                alpha: 1,
                scale: 1,
                duration: .2,
                ease: Power2.easeIn,
                force3D: true,
            }, delay + .2);

            delay += .12;
        });

        //LOCATION
        delay = 1;
        const loc = GetBy.class("location", this.item)[0];
        const icon = GetBy.class("icon", loc)[0];
        const text = GetBy.class("text", loc)[0];
        gsap.set(loc, { alpha: 0 });
        gsap.set(icon, { y: "-100%" });

        this.timeline.to(loc, { alpha: 1, duration: .3, ease: Power2.easeIn }, delay);
        let duration = .4;
        for (let i = 1; i < 6; i++) {
            let y = 60 - 10 * i;
            this.timeline.to(icon, { y: 0, duration: duration, ease: Power2.easeIn }, delay); delay += duration;
            duration = ((y * 4) / 200) / 10;
            this.timeline.to(icon, { y: `-${y}%`, duration: duration, ease: Power2.easeOut }, delay); delay += duration;
        }
        this.timeline.to(icon, { y: 0, duration: duration, ease: Power2.easeIn }, delay); delay += duration;

        //TEXT
        delay = .2;
        const claim = GetBy.class("claim", this.item)[0];
        const description = GetBy.class("description", this.item)[0];
        gsap.set(claim, { alpha: 0 });
        gsap.set(description, { alpha: 0 });

        this.timeline.to(claim, {
            alpha: 1,
            duration: .3,
            ease: Power3.easeIn,
            force3D: true,
        }, delay);

        this.timeline.to(description, {
            alpha: 1,
            duration: .4,
            ease: Power3.easeIn,
            force3D: true,
        }, delay);
    }

    show() {
        super.show();

        KoraButtons.get("SliderLocationProgress").current = this.index + 1;
        KoraButtons.get("SliderLocationProgress").button.changeText(this.data.text);
        KoraButtons.get("SliderLocationProgress").changeLink(this.data.link);
        gsap.to(this.item, { opacity: 1, ease: Power2.easeIn, duration: .2 });
        this.timeline.restart();
        this.showMedia();
        this.showColor();
    }

    showColor() {
        setTimeout(() => {
            this.item.parentNode.parentNode.setAttribute("data-palette", this.item.getAttribute("data-palette-inside"));
            BG.changePalette(this.item.getAttribute("data-palette-inside"));
        }, 300)
    }

    showMedia() {
        if (this.hasToAvoidFirstShow) return;

        this.hasToAvoidFirstShow = false;

        if (this.hasVideo) {
            this.media.play();
        }
        gsap.killTweensOf(this.mediaHolder);
        gsap.killTweensOf(this.media);

        const z = LocationSlider__Item.getNewZ();
        this.mediaHolder.style.zIndex = z

        gsap.set(this.mediaHolder, { y: "140%", alpha: 1, scale: 1.4 });
        gsap.set(this.media, { y: "-60%", z: z, scale: 1.4 });

        gsap.to(this.mediaHolder, { y: 0, scale: 1, ease: Ease.EASE_CUCHILLO_IN_OUT, duration: .8 });
        gsap.to(this.media, { y: 0, scale: 1, ease: Ease.EASE_CUCHILLO_IN_OUT, duration: .8 });
    }

    directShowMedia() {
        this.hasToAvoidFirstShow = true;

        gsap.killTweensOf(this.mediaHolder);
        gsap.killTweensOf(this.media);

        const z = LocationSlider__Item.getNewZ();
        this.mediaHolder.style.zIndex = z

        gsap.set(this.mediaHolder, { y: 0, alpha: 1, scale: 1 });
        gsap.set(this.media, { y: 0, z: z, scale: 1 });
    }

    hideMedia() {
        gsap.killTweensOf(this.mediaHolder);
        gsap.killTweensOf(this.media);

        if (this.hasVideo) {
            this.media.pause();
        }

        gsap.to(this.media, {
            scale: 2.2, ease: Ease.EASE_CUCHILLO_IN_OUT, duration: .8, onComplete: () => {

            }
        });
    }

    hide(__isDirect) {
        this.hideMedia();
        gsap.to(this.item, {
            opacity: 0, ease: Power2.easeIn, duration: .2, onComplete: () => {
                this.afterHide();
            }
        });
    }

    afterHide() {
        this.item.style.opacity = 0;
        super.afterHide();
    }
}

Scroll._registerClass('SliderLocations', ScrollItem__SliderLocations);