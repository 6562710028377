import "core-js/stable";
import "regenerator-runtime/runtime";

import { Power2, gsap } from "gsap";

import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';
import { ScrollItem__Default } from './scroll/ScrollItem__Default';
import { ScrollItem__About } from './scroll/ScrollItem__About';
import { ScrollItem__BillboardNew } from './scroll/ScrollItem__BillboardNew';
import { ScrollItem__SliderDefault } from './scroll/ScrollItem__SliderDefault';
import { ScrollItem__ParallaxCards } from './scroll/ScrollItem__ParallaxCards';
import { ScrollItem__SliderCircular } from './scroll/ScrollItem__SliderCircular';
import { ScrollItem__SliderCards3D } from './scroll/ScrollItem__SliderCards3D';
import { ScrollItem__NewsList } from './scroll/ScrollItem__NewsList';
import { ScrollItem__Footer } from './scroll/ScrollItem__Footer';
import { ScrollItem__TitleVideo } from './scroll/ScrollItem__TitleVideo';
import { ScrollItem__SliderLocations } from './scroll/ScrollItem__SliderLocations';
import { ScrollItem__WebGLSketchBillboard } from './scroll/ScrollItem__WebGLSketchBillboard';
import { ScrollItem__HighlightText } from './scroll/ScrollItem__HighlightText';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isMobile, isSmartphone, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { C, GetBy } from "./_app/cuchillo/core/Element";
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';
import LazyLoader from './_app/cuchillo/loaders/LazyLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Home from './pages/Home';
import New from './pages/New';
import Legal from './pages/Legal';

import BG from './_app/cuchillo/layout/Background';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Header from './layout/Header';
import Loading from './layout/Loading';
// import ScrollTop from './layout/ScrollTop';
import Wrap from './layout/Wrap';
import Cursor from './_app/cuchillo/cursor/Cursor';

import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';
import WinCookies from './windows/WinCookies';
import WinBookNow from "./windows/WinBookNow";
import Preloader from "./layout/Preloader";
import Sidemenu from "./layout/Sidemenu";
import Language from "./_app/cuchillo/utils/Language";
import Cookies from "./_app/cuchillo/utils/Cookies";
import { Ease } from "./_app/cuchillo/utils/Ease";
import { KoraButtons } from "./components/KoraButtons";
import Sketch from "./3D/Sketch";
import HorizontalScrollLoader from "./_app/cuchillo/loaders/HorizontalScrollLoader";
import { Analytics } from "./_app/cuchillo/core/Analytics";

export default class Main {
  static stats;
  static hasAjax = false;

  static init() {
    Basics.id = "Kora_v010"; // ID para cookies   

    //Si estamos en BookingStep o en mobile quitamos el ajax
    gsap.ticker.fps(60);
    this.hasAjax = !!GetBy.id("BookingStep")? false : false;//!isMobile;

    Language.init();

    /* REMOVE UNNECESSARY DOM*/
    const selector = isSmartphone ? "data-remove-smartphone" : "data-remove-desktop";
    [...GetBy.selector(`[${selector}]`)].map((item) => C.remove(item));

    KoraButtons.init();
    Header.init();

    if (!isSmartphone) Sketch.init();

    Cursor.init(document.body, {
      color: "#000000",
      fontStyle: {
        size: 16,
        fontFamily: "SweetSansProMedium"
      }
    }, { size: 0 }, { alpha: 0, size: 0 });

    gsap.config({
      autoSleep: 60,
      force3D: true,
      nullTargetWarn: false,
      trialWarn: true
    });

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: this.hasAjax }); // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    ControllerWindow.init(); // Control ventanas

    BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
    //WinCookies.init(); // Checkea y saca el aviso de cookies

    Analytics.isGTM = true;

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.add(new LazyLoader()); // Carga/Precarga de imgs
    LoaderController.add(new HorizontalScrollLoader()); // Carga/Precarga de imgs
    LoaderController.update = progress => Preloader.update(progress);
    LoaderController.onComplete = () => Main.setup();

    this.doCuchilloInfo();
    
    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      gsap.ticker.add(() => { Main.loop(); });
    }

    Preloader.init();
    LoaderController.init();

    if (window.location.hash === "#book" && !GetBy.id("BookingStep")) {
      setTimeout(()=> {ControllerWindow.toggle("book-now")}, 500);
    }

    //Abre la ventana de BOOK si era la ulima pagina que habiamos visitado. Lo quitamos de momento
    /*if(localStorage.getItem('isBookOpen') == "true" && !GetBy.id("BookingStep")) {
      ControllerWindow.toggle("book-now");
    } else {
      localStorage.setItem('isBookOpen') == "false";
    }*/
  }
  
  static setup() {
    Sidemenu.init();
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder, this.hasAjax);
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      //Cursor.start();
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      //Cursor.hide();
      Sidemenu.hide();
      console.log('Page hide');
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Win.ON_SHOW, () => {
      console.log('Window show');
      Scroll.setEnabled(false);
      Sidemenu.hide();
    });
    EventDispatcher.addEventListener(Sidemenu.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Sidemenu.ON_SHOW, () => {
      Scroll.setEnabled(false);
      ControllerWindow.hideAll();
    });
  }

  static resize() {
    BG.resize();
    Header.resize();
    Sidemenu.resize();
    Sketch.resize();
    Cursor.resize();
    InterfaceCanvas.resize();
    Scroll.resize();
    ControllerPage.resize();

    LoaderController.get('LazyLoader').resize();

    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    BG.loop();
    Header.loop();
    Sidemenu.loop();
    Sketch.loop();
    Cursor.loop();
    InterfaceCanvas.loop();

    if (Scroll.isScrolling) Scroll.loop();

    LoaderController.get('LazyLoader').loop();
  }

  static loopDebug() {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static doCuchilloInfo() {
    console.log('%cby Cuchillo', 'background: #000; color: #bada55; padding:25px 100px;');
    console.log('⟶ http://cuchillo.studio');
    console.log('⟶ https://www.instagram.com/_cuchillo');
    console.log('⟶ https://twitter.com/somoscuchillo');
    console.log('⟶ https://twitter.com/mr__corrales');
    console.log('');
    console.log('Gsap by Greenshock');
    console.log('⟶ https://greensock.com');
    console.log('');
    console.log('Font: Fraktion Mono by Pangram Pangram');
    console.log('⟶ https://pangrampangram.com/products/fraktion');
    console.log('');
    console.log('SVGOMG');
    console.log('⟶ https://jakearchibald.github.io/svgomg/');
    console.log('');
    console.log('Favicon Generator');
    console.log('⟶ https://realfavicongenerator.net');
  }

  static setWorker() {
    if ('serviceWorker' in navigator && !isDebug) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
